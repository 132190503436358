import * as types from '@/types';
import api, { getImageURL, isMobile } from '@/api';
export function parseListing(listing) {
    var amenities = [];
    // 0 ( Featured ? "F" : "-" ) +
    // 1 ( Pool ? "P" : "-" ) +
    // 2 ( Spa ? "S" : "-" ) +
    // 3 ( WiFi ? "W" : "-" ) +
    // 4 ( AC ? "A" : "-" ) +
    // 5 ( Pets ? "p" : "-" ) +
    // 6 ( NearSki ? "s" : "-" ) +
    // 7 ( NearGolf ? "g" : "-" ) +
    // 8 ( NearFishing ? "f" : "-" ) +
    // 9 ( NearVillage ? "v" : "-" ) +
    // 10 ( Seasonal_Vacation ? "-" : "V" );
    var amenityTypes = [
        // the string is the class name
        // [listing.options[0] == 'P', "FEATURED"],
        [listing.options[1] == 'P', 'pool'],
        [listing.options[2] == 'S', 'spa'],
        [listing.options[3] == 'W', 'wifi'],
        [listing.options[4] == 'A', 'a_c'],
        [listing.options[5] == 'p', 'pets'],
        [listing.options[6] == 's', 'ski'],
        [listing.options[7] == 'g', 'golf'],
        [listing.options[8] == 'f', 'fishing'],
        [listing.options[9] == 'v', 'village'],
        // [listing.options[10] == 'V', "SEASONAL_VACATION"],
        [listing.options[11] == 'G', 'gondola'],
        [listing.options[12] == 'X', 'garage'],
        [listing.options[13] == 'N', 'NewListing'],
        [false, 'IsEcoFriendly'] // ecoFriendly
    ];
    for (var _i = 0, amenityTypes_1 = amenityTypes; _i < amenityTypes_1.length; _i++) {
        var amenity = amenityTypes_1[_i];
        if (amenity[0]) {
            amenities.push(amenity[1]);
        }
    }
    //console.log(listing,listing.isEcoFriendly,listing.id);
    if (listing.isEcoFriendly) {
        amenities.push('IsEcoFriendly');
    }
    var propertyPriceClass = 'Budget';
    if (listing.ptypepr == 2) {
        propertyPriceClass = 'Midrange';
    }
    if (listing.ptypepr == 3) {
        propertyPriceClass = 'Luxury';
    }
    if (listing.ptypepr == 4) {
        propertyPriceClass = 'Elite';
    }
    var propertyTypeName = 'Condo/Townhouse';
    if (listing.ptype == 3) {
        propertyTypeName = 'House';
    }
    if (listing.ptype == 4) {
        propertyTypeName = 'Cabin';
    }
    if (listing.ptype == 5) {
        propertyTypeName = 'Resort';
    }
    var images = (listing.images || []).map(function (image) {
        return {
            listingId: listing.id,
            imageId: image.ImgNum,
            fileName: image.FileName
        };
    });
    var getThumbnail = function () {
        return images.length == 0
            ? getImageURL({
                listingId: listing.id,
                imageId: 1,
                fileName: '1.jpg'
            }, types.ImageSize.tiny)
            : getImageURL({
                listingId: listing.id,
                imageId: images[0].imageId,
                fileName: images[0].fileName
            }, types.ImageSize.tiny);
    };
    var getMainImage = function () {
        return images.length == 0
            ? getImageURL({
                listingId: listing.id,
                imageId: 1,
                fileName: '1.jpg'
            }, isMobile() ? types.ImageSize.large : types.ImageSize.medium)
            : getImageURL({
                listingId: listing.id,
                imageId: images[0].imageId,
                fileName: images[0].fileName
            }, isMobile() ? types.ImageSize.large : types.ImageSize.medium);
    };
    return {
        id: +listing.id,
        locationid: +listing.locid,
        pid: +listing.pid,
        title: listing.title,
        body: listing.body,
        details: listing.details,
        notices: listing.notices,
        thumbnail: getThumbnail,
        mainImage: getMainImage,
        href: { name: 'listing', params: { id: +listing.id, slug: listing.slug } },
        phone: listing.phone,
        managerEmail: listing.managerEmail,
        fullAddress: listing.fullAddress,
        availability: GetAvailability(listing.availability),
        availabilityFirstDate: listing.availabilityFirstDate,
        damageInsurance: listing.di,
        serviceTax: listing.stax,
        lodgingTax: listing.ltax,
        matterPortID: listing.matterPortID,
        images: images,
        //type: propertyPriceClass,
        activated: new Date(listing.activated),
        onSale: listing.onSale ? new Date(listing.onSale) : undefined,
        onSalePct: listing.onSale ? listing.flashSale : undefined,
        position: {
            lat: listing.lat,
            lng: listing.lon
        },
        priority: listing.priority,
        options: {
            featured: listing.options[0] == 'F',
            discounted: listing.disc && listing.disc.length > 0,
            IsEcoFriendly: listing.isEcoFriendly,
            petsAllowed: listing.options[5] == 'p',
            complexName: listing.complex || '',
            isSeasonal: listing.options[10] != 'V',
            propertyTypeID: listing.ptype,
            propertyTypeName: propertyTypeName,
            propertyTypePrice: listing.ptypepr,
            propertyTypePriceName: propertyPriceClass,
            allowBookingFromWebsite: listing.bookable,
            isShortTerm: listing.isShortTerm,
            isLongTerm: listing.isLongTerm,
            newListing: listing.options[13] == 'N',
            onTrackHs: listing.onTrackHs
        },
        rooms: {
            bedrooms: listing.bedrooms,
            bathrooms: listing.bathrooms,
            sleeps: listing.sleeps
        },
        amenities: amenities,
        rates: parseRates(listing.rates),
        fees: parseFees(listing.fees),
        resFeeCalcID: listing.resFeeCalcID,
        meta_Title: listing.meta_Title,
        meta_Description: listing.meta_Description,
        meta_Keywords: listing.meta_Keywords,
        blackouts: parseBlackouts(listing.rawBlackouts),
        discounts: parseDiscounts(listing.disc)
    };
}
function parseRates(r) {
    if (typeof r === 'undefined')
        return [];
    return r.map(function (v) {
        var p = v.split('|');
        return {
            name: p[0],
            startOn: api.parseDate(p[1]),
            endOn: api.parseDate(p[2]),
            weekdayRate: parseFloat(p[3]),
            weekendRate: parseFloat(p[4]),
            minimumNights: parseInt(p[5]),
            startDay: parseInt(p[6])
        };
    });
}
function parseBlackouts(raw) {
    if (typeof raw === 'undefined')
        return [];
    return raw.map(function (r) {
        return { locationId: r.l, from_date: r.f, to_date: r.t };
    });
}
function parseDiscounts(raw) {
    if (typeof raw === 'undefined')
        return [];
    return raw.map(function (d) {
        return {
            code: d.c,
            startOn: api.parseDate(d.s),
            endOn: api.parseDate(d.e),
            percent: d['%'],
            fixedAmount: d['$'],
            useBlackoutDates: d.f && d.f.indexOf('B') > -1,
            weekDaysOnly: d.f && d.f.indexOf('W') > -1,
            overwriteFees: d.f && d.f.indexOf('F') > -1,
            minNights: parseFloat(d.d.split('-')[0]),
            maxNights: parseFloat(d.d.split('-')[1]) || 999,
            isLastMinute: d.lm,
            numDays: d.nd,
            maxOccupiedDays: d.od
        };
    });
}
function parseFees(f) {
    if (typeof f === 'undefined')
        return null;
    return f.map(function (v) {
        var p = v.split('|');
        return {
            code: p[0],
            amount: parseFloat(p[1]),
            isPercentage: p[2] == '1',
            applyAfterTaxes: p[3] == '1'
        };
    });
}
function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
}
function addDays(date, days) {
    var dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}
function GetAvailability(base64str) {
    if (base64str == null)
        return null;
    var result = [];
    var n = 0;
    var data = atob(base64str);
    for (var i = 0; i < data.length; i++) {
        n = data.charCodeAt(i);
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
    }
    return result;
}
export function Price(listing, Arrive, Depart) {
    // ??? calculate in API?
    if (!listing.dailyRates) {
        var numDays = 365;
        var dailyRates = [];
        var minStay = [];
        for (var i = 0; i < numDays; i++) {
            dailyRates[i] = 0;
            minStay[i] = 0;
        }
        if (listing.rates && listing.fees) {
            for (var _i = 0, _a = listing.rates; _i < _a.length; _i++) {
                var rr = _a[_i];
                var startingNdx = datediff(listing.availabilityFirstDate, new Date(rr.startOn));
                var endingNdx = datediff(listing.availabilityFirstDate, new Date(rr.endOn));
                if (startingNdx < 0)
                    startingNdx = 0;
                if (endingNdx >= numDays)
                    endingNdx = numDays - 1;
                var d = addDays(listing.availabilityFirstDate, startingNdx);
                for (var i = startingNdx; i <= endingNdx; i++) {
                    dailyRates[i] = d.getDay() == 5 || d.getDay() == 6 ? rr.weekendRate : rr.weekdayRate;
                    minStay[i] = rr.minimumNights;
                    d = addDays(d, +1);
                }
            }
        }
        listing.dailyRates = dailyRates;
    }
    var result = 0;
    if (Arrive && Depart) {
        var startingNdx = datediff(new Date(listing.availabilityFirstDate), Arrive);
        var endingNdx = datediff(new Date(listing.availabilityFirstDate), Depart);
        if (startingNdx < 0)
            startingNdx = 0;
        if (endingNdx > listing.dailyRates.length)
            endingNdx = listing.dailyRates.length;
        for (var i = startingNdx; i <= endingNdx; i++) {
            result += listing.dailyRates[i];
        }
    }
    if (result == 0)
        return '&nbsp;';
    return "$".concat(result);
}
